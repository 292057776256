// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H2 from "../../styleguide/components/Heading/H2.res.js";
import * as H4 from "../../styleguide/components/Heading/H4.res.js";
import * as Button from "../../styleguide/components/Button/Button.res.js";
import * as Container from "../../styleguide/components/Container/Container.res.js";
import * as SuccessImage from "../product-reservation/confirm/SuccessImage.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Routes_Marketplace from "../../routes/common/Routes_Marketplace.res.js";
import * as CartReservationCompleteScss from "./CartReservationComplete.scss";

var css = CartReservationCompleteScss;

function CartReservationComplete$default(props) {
  return JsxRuntime.jsx(Container.make, {
              className: Cx.cx([
                    css.layout,
                    css.additionalPadding
                  ]),
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(SuccessImage.make, {}),
                      JsxRuntime.jsx(H2.make, {
                            className: css.formTitle,
                            children: "Here's What Happens Next!"
                          }),
                      JsxRuntime.jsx("p", {
                            children: "You'll be contacted by a representative within 24-hours."
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(H4.make, {
                                    className: css.buttonContainerHeading,
                                    children: "Want to make more reservations?"
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Button.AsLink.make, {
                                          href: Routes_Marketplace.colocation,
                                          size: "MD",
                                          color: "Primary",
                                          expanded: false,
                                          className: css.button,
                                          children: "Visit Marketplace"
                                        }),
                                    className: css.buttonContainer
                                  })
                            ]
                          })
                    ],
                    className: css.formContainer
                  })
            });
}

var $$default = CartReservationComplete$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
